import React from "react";
import { concatClassNames as cn } from "@sys42/utils";

import {
  ButtonPrimaryLarge,
  CircledText,
  Headline1Sans,
  Overline,
  Faqs,
  urlCreateAccount,
  urlBlog,
  FeatureIcon,
  featureOverviewFeatures,
  advertedPoolSize,
} from "@userbrain/website-ui";

import { twoColumnsInviteYourOwn } from "../../helpers";

import Layout from "../../layouts/layout";
import { FeatureGrid } from "../../components/FeatureGrid";
import { FeatureOverview } from "../../components/FeatureOverview";
import { GetStartedBox } from "../../components/GetStartedBox";
import { Intro } from "../../components/Intro";
//import { MeetOurTesters } from '../../components/MeetOurTesters';
import { ThreeColumnsIcons } from "../../components/ThreeColumnsIcons";
import { ThreeColumnsSteps } from "../../components/ThreeColumnsSteps";
import { TwoColumns } from "../../components/TwoColumns";
import { WavySeparator } from "../../components/WavySeparator";

import * as helperStyles from "../helpers.module.css";
import * as styles from "./styles/tester-pool.module.css";

import imageIconTesterPool from "@userbrain/website-ui/images/feature-icon-tester-pool.svg";
import imgSessions from "../../images/sessions.jpg";
import imgBuzzer2 from "../../images/buzzer2.jpg";
import imgPortrait1 from "../../images/portrait-1.svg";
import imgPortrait2 from "../../images/portrait-2.svg";
import imgPortrait3 from "../../images/portrait-3.svg";
import imgPortrait4 from "../../images/portrait-4.svg";
import imageFeatureGrid1 from "../../images/grid/tester-pool.min.jpg";
import imageFeatureGrid2 from "../../images/grid/tester-demographics.jpg";
import imageFeatureGrid3 from "../../images/grid/tester-globe.jpg";
import imageFeatureGrid4 from "../../images/grid/tester-home.jpg";
import imgOnboardingProcess from "../../images/onboardingProcess.jpg";
import imgQualificationTest from "../../images/qualificationTest.jpg";
import imgStarRatings from "../../images/starRatings.jpg";
import imgTestWithRealPeople from "../../images/lineIcons/test-with-real-people.svg";
import imgRecruitmentCostIncluded from "../../images/lineIcons/recruitment-costs-included.svg";
import img100PctSatisfactionGuarantee from "../../images/lineIcons/100pct-satisfaction-guarantee.svg";

export default function TesterPool() {
  return (
    <Layout
      activeMenuItem={"features/tester-pool"}
      title={"Tester pool"}
      metaDescription={`We know you’re busy — let’s take finding test users off your plate. We’ve got a pool of ${advertedPoolSize} quality assured user testers to choose from.`}
    >
      <article>
        <header className={styles.header}>
          <FeatureIcon className={styles.featureIcon}>
            <img src={imageIconTesterPool} alt={"A head with glasses"} />
          </FeatureIcon>
          <Overline className={styles.overline}>Tester Pool</Overline>
          <Headline1Sans className={styles.title}>
            <span className={helperStyles.h1Serif}>
              Tap into our pool of {advertedPoolSize}{" "}
            </span>
            quality assured{" "}
            <span className={helperStyles.h1Serif}>testers.</span>
          </Headline1Sans>
          <ButtonPrimaryLarge
            className={styles.button}
            customTag={"a"}
            href={urlCreateAccount}
          >
            Start free trial
          </ButtonPrimaryLarge>
          <div className={styles.buttonSubtext}>No credit card required</div>
          <img
            className={styles.headerPortrait1}
            src={imgPortrait1}
            alt="Lineart Portrait 1"
          />
          <img
            className={styles.headerPortrait2}
            src={imgPortrait2}
            alt="Lineart Portrait 2"
          />
          <img
            className={styles.headerPortrait3}
            src={imgPortrait3}
            alt="Lineart Portrait 3"
          />
          <img
            className={styles.headerPortrait4}
            src={imgPortrait4}
            alt="Lineart Portrait 4"
          />
        </header>

        <WavySeparator />

        <div className={helperStyles.container}>
          <Intro
            className={cn(styles.intro, helperStyles.spacingLg)}
            title={
              "We know you’re busy — let’s take finding test users off your plate."
            }
            titleWidth={"17em"}
            content={
              "Gathering people, scheduling test times, and moderating them is time-consuming and costly. With Userbrain, there’s none of that — simply set up your test and go. It’s our job to find a bunch of testers that match your criteria. All you have to do now is sit back and watch the results fly in."
            }
            image1Src={imgSessions}
            image1Alt={"Sessions"}
            image2Src={imgBuzzer2}
            image2Alt={"Hand pressing a buzzer"}
            statement={
              <>
                User testing
                <br />
                <CircledText>minus the fuss.</CircledText>
              </>
            }
          />
        </div>
        <div
          className={cn(
            helperStyles.container,
            helperStyles.container_noPaddingMobile
          )}
        >
          <FeatureGrid
            className={helperStyles.spacingLg}
            title={"We’ve got the people to test your UI & UX."}
            features={[
              {
                imageSrc: imageFeatureGrid1,
                imageAlt: "Thousands af quality assured testers",
                captionBottom: "Thousands of quality assured testers",
                customNode: (
                  <>
                    <svg
                      viewBox="0 0 50 20"
                      className={styles.featureGridAdvertedPoolSize}
                    >
                      {/* inherit font and color and kerning/letter spacing and font-weight from the HTML node that contains the SVG*/}
                      <text
                        x="25"
                        y="16"
                        textAnchor="middle"
                        fontSize="16"
                        fill="currentColor"
                        letterSpacing={-1}
                        fontWeight="600"
                        fontKerning="auto"
                      >
                        {advertedPoolSize}
                      </text>
                    </svg>
                  </>
                ),
              },
              {
                imageSrc: imageFeatureGrid2,
                imageAlt: "Demographic Targeting and Screeners",
                captionTop: "Demographic Targeting and Screeners",
              },
              {
                imageSrc: imageFeatureGrid3,
                imageAlt: "Located around the globe",
                captionTop: "Located around the globe",
              },
              {
                imageSrc: imageFeatureGrid4,
                imageAlt: "Everyday people in normal surroundings",
                captionBottom: (
                  <>
                    Everyday people in
                    <br />
                    normal surroundings
                  </>
                ),
                captionAlternativeColor: true,
              },
            ]}
          />
        </div>
        <div
          className={cn(
            helperStyles.container,
            helperStyles.wavyLineBackground
          )}
        >
          <ThreeColumnsSteps
            className={helperStyles.spacingLg}
            title={"How we check and assure the quality of our user testers."}
            titleWidth={"16em"}
            col1Image={imgOnboardingProcess}
            col1Alt={"Onboarding process"}
            col1Heading={"Onboarding process"}
            col1Text={
              "Applicants first run through an onboarding process where they watch tutorials of examples on how to test and what they can expect."
            }
            col2Image={imgQualificationTest}
            col2Alt={"Qualification test"}
            col2Heading={"Qualification test"}
            col2Text={
              "They will then receive a qualification test, which we check manually. If we’re happy, they are recruited into our user tester pool and we send them tests."
            }
            col3Image={imgStarRatings}
            col3Alt={"Star ratings"}
            col3Heading={"Star ratings"}
            col3Text={
              "Testers get a 1-5 star rating for every test, which ensures consistent quality. Our current testers’ average is an impressive 4.4 out of 5 stars."
            }
          />
        </div>

        {/*      <MeetOurTesters
        className={helperStyles.spacingLg}
        title={'Introducing our trusted testers:'}
      />*/}

        <div className={helperStyles.container}>
          <ThreeColumnsIcons
            className={helperStyles.spacingLg}
            title={"Access to reliable user testers — satisfaction guaranteed."}
            col1Image={imgTestWithRealPeople}
            col1Alt={"Group of people"}
            col1Heading={"Test with real people."}
            col1Text={
              "Getting people to test your product in their normal surroundings just became easy."
            }
            col2Image={imgRecruitmentCostIncluded}
            col2Alt={"Globe"}
            col2Heading={"Recruitment costs included."}
            col2Text={
              "With Userbrain, tester recruitment costs are always included – no surprise follow-up fees."
            }
            col3Image={img100PctSatisfactionGuarantee}
            col3Alt={"OK gesture"}
            col3Heading={"100% satisfaction guarantee."}
            col3Text={
              "If you’re not happy with a tester, we’ll arrange another tester to redo the test."
            }
          />

          <TwoColumns
            className={helperStyles.spacingLg}
            {...twoColumnsInviteYourOwn}
          />

          {/* Todo: Should we hide the "We still didn’t answer all of your questions?" in this context? */}

          <Faqs
            className={helperStyles.spacingLg}
            questions={[
              {
                question: "Do you support screening questions?",
                answer: (
                  <>
                    Yes. You can use a questionnaire to filter suitable
                    participants for your user tests.{" "}
                    <a
                      href={`${urlBlog}/new-feature-filter-testers-screening-questions`}
                    >
                      Learn more
                    </a>
                  </>
                ),
              },
              {
                question: "How do you prevent professional user testers?",
                answer:
                  "Although we want our testers to be fantastic at what they do, we believe it is advisable to avoid expert or professional testers. This is why we limit the yearly number of tests a tester gets. We also ensure that a tester only gets one specific customer once a year. Unless, of course, you choose otherwise.",
              },
              {
                question: "What countries can I test in?",
                answer:
                  "Userbrain currently supports testing in the United States, Canada, United Kingdom, Austria, Germany, Australia, and South Africa.",
              },
              {
                question: "How fast do I get my first user test results?",
                answer:
                  "You’ll get your results within a few hours — depending on how many tests you order and the demographics and device types you choose. Generally speaking, we don’t like to keep you waiting.",
              },
              {
                question:
                  "Userbrain tester pool vs. testing with your own testers, what’s the difference?",
                answer:
                  "By testing with Userbrain testers you can save time, money and still get the results you need. On the other hand, testing with your own testers gives you the option to test with a very specific target audience and/or language. Both have their benefits and ultimately it’s up to you. If you choose to test with our pool of testers, it means you can set up your test, relax and get results in just a few hours. You can also choose basic demographics. When testing with your own testers you can choose very specific demographics but have to recruit, pay, and project them yourself. Generally speaking, we’ve discovered that no matter what the demographics of a tester are, they find out what’s working for your product — and what’s not.",
              },
            ]}
          />

          <GetStartedBox className={helperStyles.spacingLg} />
        </div>
        <div
          className={cn(
            helperStyles.container,
            helperStyles.container_featureOverview
          )}
        >
          <FeatureOverview
            className={helperStyles.spacingLg}
            title={
              "Userbrain features for easy, unmoderated remote user testing"
            }
            titleWidth={"16em"}
            features={featureOverviewFeatures}
          />
        </div>
      </article>
    </Layout>
  );
}
